import React from 'react';
import './ListIconTitleDesc.css';
import { useThemeContext } from '../context/ThemeContext';

const SIZES = ['list--size-s', 'list--size-m', 'list--size-l'];

const TYPES = ['list--type--icon-title', 'list--type--icon-title-desc'];

export const ListIconTitleDesc = ({
    children,
    type,
    onClick,
    listSize,
    d,
    id,
    listTitle, /*ej: título de la lista */
    listSource, /* ej: fuente: WAI */
    className, /* lista de clases css */
    listType /*ej: listIT, listITD, o listITDB */
}) => {
    const checkListSize = SIZES.includes(listSize) ? listSize : SIZES[0];
    const checkListType = TYPES.includes(listType) ? listType : TYPES[0];

    const {contextTheme} = useThemeContext()
    
    return (
        <div className={`${className} container-child ${listType}-div ${listType}-div--${contextTheme}`}>{listTitle}
            <ul
            className={`${listType}-ul ${listType}-ul--${contextTheme}`}
            onClick={onClick}
            type={type}
            id={id}>
                {children}
            </ul>
            {listSource?
            <div className={`${listType}-source ${listType}-source--${contextTheme} margin-bottom-s`}>{listSource}</div>:
            null}
        </div>
    )
}