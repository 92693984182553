import React from 'react';
import './ListCustomBullet.css';
import { useThemeContext } from '../context/ThemeContext';

/*const STYLES = ['list--light__mode', 'list--dark__mode'];*/
const STYLES = ['list--style-title', 'list--style-title-description', 'list--style-icon', 'list--style-icon-title', 'list--style-icon-title-description'];
const SIZES = ['list--size-s', 'list--size-m', 'list--size-l'];

export const ListCustomBullet = ({
    children,
    type,
    onClick,
    d,
    id,
    listTitle,
    listType,
    className
}) => {

    const {contextTheme} = useThemeContext()
    
    return (
        <div className={`${className} div_list-custom-bullet--${contextTheme}`}>{listTitle}
        {listType === 'ol'? 
        <ol
        className={`list-custom-bullet list-custom-bullet--${contextTheme} custom-bullet-ol`}
        onClick={onClick}
        type={type}
        id={id}>
            {children}
        </ol>:
        <ul
        className={`list-custom-bullet list-custom-bullet--${contextTheme}`}
        onClick={onClick}
        type={type}
        id={id}>
            {children}
        </ul>}
        </div>
    )
}