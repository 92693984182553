import React from 'react'
import {Link} from 'react-router-dom'
import './CardProfile.css'
import { useThemeContext } from '../context/ThemeContext';

import { Button } from './ButtonIcon'

function CardProfile(props) {

    const {contextTheme} = useThemeContext()

    return (
        <>
        <div className={`${props.css} cp-card--${contextTheme}`}>
            {props.src!==''?(
                <img className="cp-img"
                src={props.src}
                alt={props.alt}/>
            ):null}
            {props.name!==''?(
                <p className='cp-name' translate='no'>{props.name}</p>
            ):null}
            {props.title!==''?(
                <p className="cp-title">{props.title}</p>
            ):null}
            {props.subtitle!==''?(
                <p className='cp-subtitle'>{props.subtitle}</p>
            ):null}
            <span className='cp-line'></span>

            {props.description!==''?(
            <p className="cp-description">
                {props.description}
            </p>
            ):null}
            {props.url!==''?(
            <div className="cp-action">
                {/*
                <a className="cp-a" href={props.url}><i className="fa fa-linkedin icon-color"></i></a>
                */}
                {/*}
                <Button
                id='profileButton'
                buttonStyle={`btn--${contextTheme}`}
                d=''
                >Ver perfil
                </Button>
                */}
                <a className={`button btn--${contextTheme} btn--medium`} href={props.url}>Ver perfil</a>
            </div>
            ):null}
        </div>
        </>
    )
}

export default CardProfile