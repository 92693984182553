import React from 'react';
import '../App.css';
import './Icon.css';
import { useThemeContext } from '../context/ThemeContext';

export const Icon = ({
    cssClassIcon,
    Image,
    cssClassName
}) => {
    const {contextTheme} = useThemeContext()
    return (
        <>
        <Image className={`${cssClassIcon} ${cssClassIcon}-${contextTheme} ${cssClassName}`} src={Image} />
        </>
    )
}