import React from 'react'
import './CardQuote.css'
import { useThemeContext } from '../context/ThemeContext';

function CardQuote(props) {

    const {contextTheme} = useThemeContext()

    return (
        <>
        <div className={`${props.css} ${props.css}--${contextTheme}`}>
            {props.src!==''?(
                <img className="card-qt--img"
                src={props.src}
                alt={props.alt}/>
            ):null}
            <div className="card-qt--div-description">
            {props.description!==''?(
            <p className="card-qt--description">
                {props.description}
            </p>
            ):null}
            {props.subdescription!==''?(
            <p className="card-qt--subdescription">
                {props.subdescription}
            </p>
            ):null}
            </div>
        </div>
        </>
    )
}

export default CardQuote