import React from 'react';
import './ButtonIcon.css';

const STYLES = ['btn--light__mode', 'btn--dark__mode'];
const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
    to,
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    d,
    id
}) => {
    /*default style = light*/
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    /*default size = medium*/
    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
    
    return (
        <button 
        className={`button ${checkButtonStyle} ${checkButtonSize} outline-offset`}
        onClick={onClick}
        type={type}
        id={id}>
            {children}
            {d?(
                <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
                    <path clipRule="evenodd" d={d} fillRule="evenodd"></path>
                </svg>
            ):null}
        </button>
    )
    
}