import {createContext, useContext, useState} from 'react'

export const ThemeContext = createContext()
export const ThemeContextProvider = ({children}) => {
    //const[contextTheme, setContextTheme] = useState('light__mode');
    // inicializa el contexto con tema "light__mode" o "dark__mode"
    //const[contextTheme, setContextTheme] = useState(localStorage.getItem('isDarkMode') === 'true'?'dark__mode':'light__mode');
    // inicializa contexto segun preferencias usuario
    const[contextTheme, setContextTheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches?'dark__mode':'light__mode');

    const values = {contextTheme, setContextTheme}
    
    return (
        <ThemeContext.Provider value={values}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => {
    const context = useContext(ThemeContext)
    return context
}