import React from 'react';
import '../App.css';
import './Quote.css';
import { useThemeContext } from '../context/ThemeContext';
import {ReactComponent as QuoteLeft} from '../assets/images/quote.svg';
import {ReactComponent as QuoteRight} from '../assets/images/quote.svg';


function Quote(props) {

    const {contextTheme} = useThemeContext()

    return (
        <>
        <figure className={`blockquote-container blockquote-container-${contextTheme}`}>
            <blockquote className={`${props.className} blockquote-${contextTheme}`}>
                {props.hasIcon?(
                    <QuoteLeft className={`quote-${contextTheme} quote-left`} />
                ):null}

                {props.quote}

                {props.hasIcon?(
                    <QuoteRight className={`quote-${contextTheme} quote-right`} />
                ):null}
            </blockquote>
            <figcaption className={`figcaption-${contextTheme}`}>
                <span className='dash'>&mdash; </span>{props.author}
            </figcaption>
       </figure>
    </>
    )
}

export default Quote;