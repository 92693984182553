import React from 'react'
import '../../App.css'
import Quote from '../Quote'
import {Link} from 'react-router-dom';

import img_pedestrian from '../../assets/images/image-pedestrian-min.jpg'

import {ReactComponent as IcoBarriersComplex} from '../../assets/images/ico_img_barriers_complex.svg'
import {ReactComponent as IcoBarriersKeyboard} from '../../assets/images/ico_img_barriers_keyboard_navigation.svg'
import {ReactComponent as IcoBarriersContrast} from '../../assets/images/ico_img_barriers_low_contrast.svg'

import {ReactComponent as IcoBarriersComplexRemoved} from '../../assets/images/ico_img_barriers_removed_complex.svg'
import {ReactComponent as IcoBarriersKeyboardRemoved} from '../../assets/images/ico_img_barriers_removed_keyboard_navigation.svg'
import {ReactComponent as IcoBarriersContrastRemoved} from '../../assets/images/ico_img_barriers_removed_low_contrast.svg'

import {ReactComponent as IcoBenefitsAudience} from '../../assets/images/ico_img_benefits_audience_4.svg'
import {ReactComponent as IcoBenefitsBrand} from '../../assets/images/ico_img_benefits_brand.svg'
import {ReactComponent as IcoBenefitsInnovation} from '../../assets/images/ico_img_benefits_innovation.svg'
import {ReactComponent as IcoBenefitsLegalRisk} from '../../assets/images/ico_img_benefits_legal_risk.svg'

import {ReactComponent as IcoOBenefitsEnvironment} from '../../assets/images/ico_o_benefits_environment_limitation_img.svg'
import {ReactComponent as IcoOBenefitsInjury} from '../../assets/images/ico_o_benefits_injury_img.svg'
import {ReactComponent as IcoOBenefitsOldPerson} from '../../assets/images/ico_o_benefits_old_person_img.svg'
import {ReactComponent as IcoOBenefitsSmallScreen} from '../../assets/images/ico_o_benefits_small_screen_img.svg'
import {ReactComponent as IcoOBenefitsPWD} from '../../assets/images/ico_o_benefits_pwd_img.svg'


import CardQuote from '../CardQuote'
import { ListIconTitleDesc } from '../ListIconTitleDesc'

const authorText = <span>Tim Berners-Lee, Director fundador del W3C y creador de la <span lang='en-US'>World Wide Web</span></span>

const paragraphChilePercentage = <span className=''>En Chile, el <span className='bold'>17%</span> de la población tiene discapacidad, es decir, más de <span className='bold'>3 millones de personas</span>.</span>;


const barriers = 
    [
        {
            id: 100,
            name: 'Navegación por teclado no soportada',
            Image: IcoBarriersKeyboard,
            cssClassIcon: 'li-icon'
        },
        {
            id: 101,
            name: 'Colores con bajo contraste',
            Image: IcoBarriersContrast,
            cssClassIcon: 'li-icon'
        },
        {
            id: 102,
            name: 'Estructura o contenido complejo',
            Image: IcoBarriersComplex,
            cssClassIcon: 'li-icon'
        }

    ]

const barriersRemoved = 
    [
        {
            id: 200,
            name: 'Soporte para navegación por teclado',
            description: '',
            Image: IcoBarriersKeyboardRemoved,
            cssClassIcon: 'li-icon'
        },
        {
            id: 201,
            name: 'Colores con buen contraste',
            description: '',
            Image: IcoBarriersContrastRemoved,
            cssClassIcon: 'li-icon'
        },
        {
            id: 202,
            name: 'Estructura y contenido simple',
            description: '',
            Image: IcoBarriersComplexRemoved,
            cssClassIcon: 'li-icon'
        }

    ]

const otherBenefits = 
    [
        {
            id: 300,
            name: 'Personas que usan dispositivos con pantallas pequeñas',
            description: '',
            Image: IcoOBenefitsSmallScreen,
            cssClassIcon: 'li-icon'
        },
        {
            id: 301,
            name: 'Personas con discapacidad permanente',
            description: '',
            Image: IcoOBenefitsPWD,
            cssClassIcon: 'li-icon'
        },
        {
            id: 302,
            name: 'Personas con discapacidad temporal (ej: un brazo lesionado)',
            description: '',
            Image: IcoOBenefitsInjury,
            cssClassIcon: 'li-icon'
        },
        {
            id: 303,
            name: 'Personas mayores cuyas habilidades cambian con la edad',
            description: '',
            Image: IcoOBenefitsOldPerson,
            cssClassIcon: 'li-icon'
        },
        {
            id: 304,
            name: 'Personas con limitaciones de entorno (ej: estar bajo la luz del sol)',
            description: '',
            Image: IcoOBenefitsEnvironment,
            cssClassIcon: 'li-icon'
        }

    ]

const benefits = 
    {
        title: 'Beneficios',
        source: 'Fuente: Iniciativa para la Accesibilidad Web (WAI)',
        benefitsList:
        [
            {
                id: 400,
                name: 'Impulsar la innovación',
                description: '',
                Image: IcoBenefitsInnovation,
                cssClassIcon: 'li-icon'
            },
            {
                id: 401,
                name: 'Mejorar la reputación',
                description: '',
                Image: IcoBenefitsBrand,
                cssClassIcon: 'li-icon'
            },
            {
                id: 402,
                name: 'Ampliar el mercado',
                description: '',
                Image: IcoBenefitsAudience,
                cssClassIcon: 'li-icon'
            },
            {
                id: 403,
                name: 'Minimizar el riesgo legal',
                description: '',
                Image: IcoBenefitsLegalRisk,
                cssClassIcon: 'li-icon'
            }

        ]
    }

// Function component using arrow function syntax
const Home = ({refToFooter}) => {
    window.scrollTo({top: 0});

    const scrollToFooter = () => {
        if (refToFooter.current) {
          refToFooter.current.scrollIntoView();
        }
      };
    
    const barriersList = barriers.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container-dynamic'>
                <div className='li-container-dynamic-div'>
                    <Image className={cssClassIcon} src={Image} />
                    <p className='li-title'>{name}</p>
                </div>
                <p className='li-description'>{description}</p>
            </div>
        </li>);
    
    const barriersRemovedList = barriersRemoved.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                <Image className={cssClassIcon} src={Image} />
                <p className='li-title'>{name}</p>
            </div>
        </li>);

    const otherBenefitsList = otherBenefits.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                <Image className={cssClassIcon} src={Image} />
                <p className='li-description'>{name}</p>
            </div>
        </li>);

    const benefitsList = benefits.benefitsList.map(({ id, name, description, Image, cssClassIcon }) =>
        <li key={id}>
            <div className='li-container'>
                <Image className={cssClassIcon} src={Image} />
                <p className='li-title'>{name}</p>
            </div>
        </li>);

    return (
        <>
        <div className='container-parent margin-bottom-xl'>
            <Quote
                quote='El poder de la Web está en su universalidad. El acceso de todos, independientemente de la discapacidad, es un aspecto esencial'
                author={authorText}
                hasIcon='true'
            />

{/*}
            <CardQuote key='c-qt-1'
                src={img_pedestrian}
                alt='una multitud de personas caminando por la calle'
                description={paragraphChilePercentage}
                subdescription='Fuente: III Estudio Nacional de la Discapacidad 2022'
                css={'card-qt'}
            />
*/}


            <h1 className='heading-text'>
                Barreras de Accesibilidad
            </h1>

            <p className='container-child p-text'>
                Cuando los productos digitales están bien diseñados, todos pueden utilizarlos. Sin embargo, en la actualidad, muchos de ellos presentan <span className='bold'>barreras de accesibilidad</span> que dificultan o imposibilitan su uso por parte de algunas personas, como por ejemplo:
            </p>

            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSize={'list--size-s'}
                children={barriersList}>
            </ListIconTitleDesc>

            <h1 className='heading-text'>
                Productos Digitales Accesibles
            </h1>
            
            <p className='container-child p-text'>
                Los productos digitales que incorporan accesibilidad digital se transforman en <span className='bold'>productos digitales accesibles</span> que poseen, entre otras, las siguientes características: 
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSize={'list--size-s'}
                children={barriersRemovedList}>
            </ListIconTitleDesc>
            
            <h1 className='heading-text'>
                ¿A quiénes benefician?
            </h1>
            
            <p className='container-child p-text margin-bottom-s'>
                Los productos digitales accesibles mejoran la experiencia de usuario y permiten que las personas con discapacidad puedan utilizarlos. De esta manera, benefician a:
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSource=''
                listSize={'list--size-s'}
                children={otherBenefitsList}>
            </ListIconTitleDesc>
            
            <h1 className='heading-text'>
                Beneficios para las empresas
            </h1>

            <p className='container-child p-text margin-bottom-s'>
                Los productos digitales accesibles reportan grandes <span className='bold'>beneficios para las empresas</span>, ya que les permiten, entre otras cosas:
            </p>
            
            <ListIconTitleDesc
                className=''
                listType='listITD'
                listTitle=''
                listSource=''
                listSize={'list--size-s'}
                children={benefitsList}>
            </ListIconTitleDesc>

            <h1 className='heading-text'>
                ¿Quieres que tus productos digitales sean accesibles?
            </h1>
            
            <p className='container-child p-text'>
                Si quieres hacer que tu sitio web, aplicación móvil, o documento digital sea accesible, <Link to={{}} onClick={scrollToFooter} className='a-underline bold'>contáctanos</Link> y descubre cómo podemos ayudarte a crear una experiencia digital inclusiva para todos.
            </p>

        </div>

        </>
    )
}

export default Home