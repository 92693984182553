import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Breadcrumbs.css'

/*
const Breadcrumbs = ({ breadcrumbs }) => {
return (
<div className='breadcrumbs'>
{breadcrumbs.map((breadcrumb, index) => (
<span key={index}>
{index > 0 && <span className='separator'>/</span>}
{breadcrumb.link ? (
<Link to={breadcrumb.link}>{breadcrumb.label}</Link>
) : (
<span>{breadcrumb.label}</span>
)}
</span>
))}
</div>
);
};
*/

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    let breadcrumbPath = "";

    return (
        /*
        <div className="breadcrumbs">
            <Link to="/">Inicio</Link>
            {pathnames.map((name, index) => {
                breadcrumbPath += `/${name}`;
                const isLast = index === pathnames.length - 1;
                console.log(pathnames, breadcrumbPath);

                return isLast ? (
                    <span key={breadcrumbPath}> / {name}</span>
                ) : (
                    <span key={breadcrumbPath}>
                        {" "}
                        / <Link to={breadcrumbPath}>{name}</Link>
                    </span>
                );
            })}
        </div>
        */
       <div className="breadcrumbs">
            {/*<Link to="/">Inicio</Link>*/}
            {pathnames.map((name, index) => {
                let label = '';
                if(name === 'services') {
                    label = 'Servicios'
                } else if (name === 'aboutUs') {
                    label = '¿Quiénes somos?'
                } else if (name === 'accessibilityStatement') {
                    label = 'Declaración de Accesibilidad'
                }
                
                breadcrumbPath += `/${name}`;
                const isLast = index === pathnames.length - 1;
                //console.log(pathnames, breadcrumbPath);

                return isLast ? (
                    <span key={breadcrumbPath}><Link to="/">Inicio</Link> > <span className='bold'>{label}</span></span>
                ) : (
                    <span key={breadcrumbPath}>{" "}/ <Link to={breadcrumbPath}>{label}</Link></span>
                );
            })}
        </div>

    );
};


export default Breadcrumbs;